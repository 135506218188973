.App {
    display: flex;
    position: relative;
    height: 100%;
}

.centralize {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}