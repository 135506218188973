.fullscreenMain {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
}

.fullscreenHeader {
    position: relative;

    margin: 0;
    padding: 0.5rem 3rem;
    text-align: center;

    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
}

.fullscreenContent {
    margin: 0;
    padding: 0 1rem;
    font-size: 6rem;
    color: white;
}

.fullscreenContent p {
    margin: 0;
}
