.permaMain{
    position: absolute;
    right: 0;
    top: 0;
}

.permaHeader {
    background-color: #084886;
}

.permaHeader h2 {
    margin: 0;
    font-size: 4rem;
    color: #FFC500;
}

.permaHeader p {
    /* position: absolute;
    right: 0;
    top: 0;
    */
    vertical-align: center;
    margin: 0;
    padding: 0.5rem 1rem 0 0;
    color: white;
    font-size: 4rem;
}
