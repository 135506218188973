.clockFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 3rem;
}

.clockHeaderContainer {
    display: inline;
}

.clockHeader {
    color: #084886;
    margin: 0;
}

.clockHeaderMain {
    font-size: 25rem;
}

.clockHeaderSeconds {
    font-size: 12rem;
}

.dateHeader {
    color: #FFC500;
    margin: 0;
    font-size: 5rem;
	height: 10vh;
}
